export const descriptions = [
  {
    title: '产品',
    children: [
      [
        { desc: '通用文字识别', href: '/product/textin_text' },
        { desc: '图像智能处理', href: `/product/textin_image` },
        { desc: '卡证文字识别', href: '/product/textin_card' },
        { desc: '票据文字识别', href: '/product/textin_bill' },
        { desc: '文档格式转换', href: `/product/textin_conversion` },
        { desc: 'AI实验室', href: `/laboratory` },
      ],
      [
        { desc: '合同比对', href: `/product/textin_contract` },
        { desc: '合同抽取', href: `/product/textin_contract_extraction` },
        { desc: '知识管理及写作助手', href: `/product/textin_intfinq` },
        { desc: 'DocFlow 票据自动化', href: `/product/textin_docflow` },
      ],
    ],
  },
  {
    title: '服务',
    children: [
      { desc: '产品体验中心', href: '/experience/list', target: '_blank' },
      { desc: 'API文档中心', href: '/document/index?mode=debug', target: '_blank' },
      // { desc: 'Mobile SDK 免费试用', href: '/product/textin_mobile_sdk', target: '_blank' },
      { desc: '产品市场和价格', href: '/market/list', target: '_blank' },
    ],
  },
  {
    title: '关于TextIn',
    children: [
      { desc: '用户协议', href: '/terms', target: '_blank' },
      { desc: '隐私协议', href: '/privacy', target: '_blank' },
      { desc: '联系我们', href: '/contact' },
    ],
  },
];

export const ICPCode = '沪ICP备18014493号-7';

export const securityCode = '沪公网安备 31010602005698号';

export const reportCenterDesc = '上海市互联网违法和不良信息举报中心';
