import { useMemo } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import Link from '@/components/Link';
import { userModelContainer } from '@/store/userModel';
import { dashboardSitePrefixPath } from '@/utils';
import IconSvg from './ic_enterprise.svg';
import { CertificationStatus } from './constant';
import styles from './index.module.less';

const Certification = ({
  compact,
  hiddenCompany,
  style,
}: {
  compact?: boolean;
  hiddenCompany?: boolean;
  style: React.CSSProperties;
}) => {
  const {
    userInfo: { enterpriseAuthStatus: enterprise_auth_status, company },
  } = userModelContainer.useContainer();

  const certification = useMemo(() => {
    const statusMap: Record<string, any> = {
      [CertificationStatus.default]: {
        status: 'default',
        text: '未认证企业',
        shotText: '未认证',
        linkText: '立即认证',
        linkTarget: `${dashboardSitePrefixPath}/dashboard/setting/enterprise_certification`,
      },
      [CertificationStatus.progress]: {
        status: 'processing',
        text: '认证审核中',
        shotText: '认证中',
        linkText: '查看进度',
        linkTarget: `${dashboardSitePrefixPath}/dashboard/setting/enterprise_certification`,
      },
      [CertificationStatus.error]: {
        status: 'error',
        text: '认证企业失败',
        shotText: '认证失败',
        linkText: '查看详情',
        linkTarget: `${dashboardSitePrefixPath}/dashboard/setting/enterprise_certification`,
      },
      [CertificationStatus.success]: {
        status: 'success',
        text: '已认证企业',
        shotText: '已认证',
        linkText: '',
        linkTarget: `${dashboardSitePrefixPath}/dashboard/setting/enterprise_certification`,
      },
    };
    const info =
      typeof enterprise_auth_status === 'number' ? statusMap[enterprise_auth_status] : undefined;
    return (info || statusMap['0']) as {
      status: string;
      text: string;
      shotText: string;
      linkText: string;
      linkTarget: string;
    };
  }, [enterprise_auth_status]);

  const StatusDom = (
    <span
      className={classNames(
        styles['certification-status'],
        styles[`status-${certification.status}`],
        { [styles[`layout-compact`]]: compact },
      )}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span className={styles['certification-icon']}>
        <Icon component={IconSvg} />
      </span>
      <span className={styles['certification-text']}>
        {compact ? certification.shotText : certification.text}
        {certification.linkText && !compact && (
          <Link href={certification.linkTarget} className={styles['certification-link']}>
            {certification.linkText}
          </Link>
        )}
      </span>
    </span>
  );

  if (compact) {
    return StatusDom;
  }

  return (
    <div style={style}>
      {company && !hiddenCompany && <div>{company}</div>}
      {StatusDom}
    </div>
  );
};

export default Certification;
