import styles from './index.module.scss';
import { Row } from 'antd';
import classNames from 'classnames';
import { headerContainer } from '@/components/Header/store';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { getDisplayType } from '@/containers/Market/service';
import { getConsultation } from './service';
import ServiceSelect from './ServiceSelect';
import { RenderRow } from './RenderItem';
import { filetHiddenList } from './constants';

const ExperiencePopover = ({ active }) => {
  const {
    popoverClassName,
    productList: initProductList,
    productTypeList: initProductTypeList,
  } = headerContainer.useContainer();

  const [renderProList, setRenderProList] = useState<any>([]);
  const [_productList, setProductList] = useState<Record<string, any>[]>();
  const [_productTypeList, setProductTypeList] = useState();
  const [showCreateRobotModal, setShowCreateRobotModal] = useState(false);

  const productList = _productList || initProductList || [];
  const productTypeList = _productTypeList || initProductTypeList || [];

  useEffect(() => {
    renderFun();
  }, []);
  useEffect(() => {
    if (productList?.length && productTypeList?.length) {
      rendeDataList();
    }
  }, [productList, productTypeList]);

  useEffect(() => {
    // 重新打开刷新
    if (productList?.length && active) {
      getProuctList('');
    }
  }, [active]);

  const renderFun = async () => {
    getProuctTypeList();
    getProuctList('');
  };
  const rendeDataList = async () => {
    const arr: any = [];
    productTypeList.forEach((item: any) => {
      const data: any = {
        title: item.display_type,
        children: [],
      };
      productList.forEach((val: any) => {
        if (val.is_public === 1 && item.id === val.display_type) {
          val.position = 'header';
          data.children.push(val);
        }
      });
      arr.push(data);
    });
    setRenderProList(arr);
  };
  const getProuctTypeList = async () => {
    getDisplayType().then((res) => {
      setProductTypeList(res.data);
    });
  };
  const getProuctList = async (value: any) => {
    const params = {
      tags: [0],
      properties: [0],
      keyword: value,
    };
    getConsultation(params).then((res) => {
      setProductList(filetHiddenList(res.data));
    });
  };
  return (
    <>
      {!showCreateRobotModal && (
        <div
          className={classNames(styles['experience-popover-wrapper'], 'popover-effect', 'navBox')}
        >
          <div className={classNames(styles.support_wrapper, 'navLeft')}>
            <div className={classNames(styles.support, 'leftContent')}>
              <div className="navLeftTitle">体验中心</div>
              <div className="navLeftDesc">
                便捷的可视化在线体验，帮您快速了解TextIn智能文字识别及文件转换能力
              </div>
              <div className={styles.support_item}>
                <Image
                  src={'/images/contact/ic_navigation_customize@2x.png'}
                  alt=""
                  width={36}
                  height={36}
                />
                <div className={styles.desc}>准确率高</div>
              </div>
              <div className={styles.support_item}>
                <Image
                  src={'/images/contact/ic_navigation_support@2x.png'}
                  alt=""
                  width={36}
                  height={36}
                />
                <div className={styles.desc}>服务稳定</div>
              </div>
              <div className={styles.support_item}>
                <Image
                  src={'/images/contact/ic_navigation_serve@2x.png'}
                  alt=""
                  width={36}
                  height={36}
                />
                <div className={styles.desc}>多语言支持</div>
              </div>
            </div>
          </div>

          <Row
            className={classNames(
              styles['experience-popover-content'],
              popoverClassName,
              'experience-popover',
              'navContent',
            )}
          >
            <div className={styles.tools}>
              <ServiceSelect
                serviceList={productList}
                deps={active}
                afterClose={() => setShowCreateRobotModal(false)}
                className={styles.serviceSelect}
              />
              <div className={styles.toolsBottom}>
                <RenderRow data={renderProList} />
              </div>
            </div>
          </Row>

          <div className={classNames(styles.navRight, 'navRight')}>{/* 广告区 */}</div>
        </div>
      )}
    </>
  );
};
export default ExperiencePopover;
