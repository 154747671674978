import { Col, Row } from 'antd';
import { userModelContainer } from '@/store/userModel';
import { dashboardSitePrefixPath } from '@/utils';
import { HotIcon, NewIcon } from '../../Icon';
import { hotServices, isOpenKie, linkToConsole, newServices } from '../constants';
import styles from './index.module.less';
import { track } from '@/utils/track';

export const RenderItem = ({ list }) => {
  if (!Array.isArray(list)) return null;
  const { userInfo } = userModelContainer.useContainer();

  const onClick = (item) => {
    if (item.position === 'header') {
      track({ name: '按钮点击', keyword: `导航栏【体验中心】`, product_type: item.name });
    }
  };

  return (
    <>
      {list.map((cardItem) => {
        // 未登录并且有体验中心的服务
        if (cardItem.exper_center_status === 1 && !userInfo?.account) {
          return (
            <a
              className={styles.link}
              target="_blank"
              href={`/experience/${cardItem.service}`}
              rel=""
              key={cardItem.service}
              onClick={() => onClick(cardItem)}
            >
              {cardItem.name || ''}
              {newServices.includes(cardItem.service) && (
                <span className={styles.tag}>
                  {newServices.includes(cardItem.service) && <NewIcon />}
                </span>
              )}
              {hotServices.includes(cardItem.service) && (
                <span className={styles.tag}>
                  {hotServices.includes(cardItem.service) && <HotIcon />}
                </span>
              )}
            </a>
          );
        } else {
          let url = `/market/detail/${cardItem.service}`;
          const console_url = `${dashboardSitePrefixPath}${cardItem.interaction_uri}?service=${cardItem.service}`;
          if (linkToConsole(cardItem)) {
            url = userInfo?.account
              ? console_url
              : `/user/login?redirect=${encodeURIComponent(console_url)}`;
          } else if (userInfo?.account && cardItem.interaction !== -1) {
            url = console_url;
          }
          if (isOpenKie(cardItem)) {
            url = console_url;
          }
          return (
            <a
              type="link"
              className={styles.link}
              target="_blank"
              href={url}
              rel=""
              key={cardItem.service}
              onClick={() => onClick(cardItem)}
            >
              {cardItem.name || ''}
              {newServices.includes(cardItem.service) && (
                <span className={styles.tag}>
                  {newServices.includes(cardItem.service) && <NewIcon />}
                </span>
              )}
              {hotServices.includes(cardItem.service) && (
                <span className={styles.tag}>
                  {hotServices.includes(cardItem.service) && <HotIcon />}
                </span>
              )}
            </a>
          );
        }
      })}
    </>
  );
};

export const RenderRow = ({ data }) => {
  return (
    <Row className={styles['gutter-wrap']} gutter={15}>
      <Col span={6}>
        {!!data?.[0]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 大模型 */}
            <div className={styles.title}>{!!data.length && data[0].title}</div>
            <RenderItem list={data?.[0]?.children} />
          </div>
        )}
        {!!data?.[1]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 通用识别 */}
            <div className={styles.title}>{!!data.length && data[1].title}</div>
            <RenderItem list={data?.[1]?.children} />
          </div>
        )}
        {!!data?.[5]?.children?.length && (
          <div className={styles['card-item']}>
            {/* AIGC */}
            <div className={styles.title}>{!!data.length && data[5].title}</div>
            <RenderItem list={data?.[5]?.children} />
          </div>
        )}
      </Col>

      {!!data?.[3]?.children?.length && (
        <Col span={6}>
          <div className={styles['card-item']}>
            {/* 票据 */}
            <div className={styles.title}>{!!data.length && data[3].title}</div>
            <RenderItem list={data?.[3]?.children} />
          </div>
        </Col>
      )}

      <Col span={6}>
        {!!data?.[4]?.children?.length && (
          <div className={styles['card-item']}>
            {/* 格式转换 */}
            <div className={styles.title}>{!!data.length && data[4].title}</div>
            <RenderItem list={data?.[4]?.children} />
          </div>
        )}
        {!!data?.[2]?.children && (
          <div className={styles['card-item']}>
            {/* 图像处理 */}
            <div className={styles.title}>{!!data.length && data[2].title}</div>
            <RenderItem list={data?.[2]?.children} />
          </div>
        )}
      </Col>
      {!!data?.[6]?.children && (
        <Col span={6}>
          <div className={styles['card-item']}>
            {/* 卡证 */}
            <div className={styles.title}>{!!data.length && data[6].title}</div>
            <RenderItem list={data?.[6]?.children} />
          </div>
        </Col>
      )}
    </Row>
  );
};
