export enum CertificationType {
  email = 1,
  info = 2,
}

export const CertificationTypeDesc: Record<string, any> = {
  [CertificationType.email]: '邮箱认证',
  [CertificationType.info]: '资料认证',
};

export enum CertificationStatus {
  default = 0,
  progress = 1,
  success = 2,
  error = 10,
}

export const CertificationStatusDesc: Record<string, any> = {
  [CertificationStatus.progress]: '待审核',
  [CertificationStatus.success]: '通过',
  [CertificationStatus.error]: '不通过',
};

export const CertificationStatusBadge: Record<string, any> = {
  [CertificationStatus.progress]: 'processing',
  [CertificationStatus.success]: 'success',
  [CertificationStatus.error]: 'error',
};
