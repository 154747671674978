import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { getDisplayType } from '@/containers/Market/service';
import { getConsultation } from '../component/Navigation/components/ExperiencePopover/service';
import { filetHiddenList } from '../component/Navigation/components/ExperiencePopover/constants';

export type ITheme = 'dark' | 'light';

interface IHeaderProps {
  theme: ITheme;
  popoverClass?: string;
  loginRedirect?: boolean;
}

function useStore(props?: IHeaderProps) {
  const { loginRedirect = true } = props || {};
  const [popoverClassName] = useState(props?.popoverClass);
  // 用于记录header主题模式, dark模式hover后会变light
  const [theme, setTheme] = useState<ITheme>(props?.theme || 'dark');

  const [popupVisible, setPopupVisible] = useState(false);

  const [productTypeList, setProductTypeList] = useState([]);
  const [productList, setProductList] = useState<Record<string, any>[]>([]);

  const getProuctTypeList = async () => {
    getDisplayType().then((res) => {
      setProductTypeList(res.data);
    });
  };

  const getProuctList = async () => {
    const params = {
      tags: [0],
      properties: [0],
      keyword: '',
    };
    getConsultation(params).then((res) => {
      setProductList(filetHiddenList(res.data));
    });
  };

  useEffect(() => {
    getProuctTypeList();
    getProuctList();
  }, []);

  return {
    popoverClassName,
    theme,
    changeTheme: setTheme,
    loginRedirect,
    popupVisible,
    setPopupVisible,
    productList,
    productTypeList,
  };
}

export const headerContainer = createContainer(useStore);
